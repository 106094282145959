import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// gen_ext_imports

// gen_imports

/**
 * Modulo donde se puede realizar instanciación global de clases
 */
@NgModule({
  imports: [CommonModule],
  providers: [
    // gen_providers
  ],
  declarations: []
})
export class CoreModule {}
