<div class="layout">

  <a href="#" id="header-menu-button" (click)="changeSidebarStatus()" [ngClass]="{ 'header-menu-active' : menuActive }">
    <em class="pi pi-align-justify"></em>
  </a>

  <a href="#" id="topbar-profile-menu-button" (click)="userMenuActive = !userMenuActive">
    <div id="topbar-name-container">
      <span id="topbar-name">{{ user?.nombreCompleto }}</span>
      <span id="topbar-last-access">Último ingreso: {{ user?.fechaUltimaConexion }}</span>
    </div>
    <img alt="ecuador-layout" class="topbar-profile" src="assets/images/photo-user.png">
    <em class="pi pi-angle-down" style="color: white;"></em>
  </a>

  <ul class="fadeInDown" [ngClass]="{ 'usermenu-active': userMenuActive }" id="topbar-usermenu">
    <li class="topbar-name-mobile">
      <span>{{ user?.nombreCompleto }}</span>
      <span>Último ingreso: {{ user?.fechaUltimaConexion }}</span>
    </li>
    <li>
      <a href="#" (click)="getProfile()">
        <em class="pi pi-user mr-1"></em>
        <span class="topbar-item-name">Perfil</span>
      </a>
    </li>
    <li>
      <a href="{{user?.urlLogout}}">
        <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="currentColor" class="bi bi-arrow-bar-right" viewBox="0 0 20 20">
          <path fill-rule="evenodd" d="M6 8a.5.5 0 0 0 .5.5h5.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L12.293 7.5H6.5A.5.5 0 0 0 6 8zm-2.5 7a.5.5 0 0 1-.5-.5v-13a.5.5 0 0 1 1 0v13a.5.5 0 0 1-.5.5z"/>
        </svg>
        <span class="topbar-item-name">Salir</span>
      </a>
    </li>
    <li>
      <a href="{{ user?.fullUrlLogout}}" id="logout">
        <em class="pi pi-power-off mr-1"></em>
        <span class="topbar-item-name">Cerrar Sesion</span>
      </a>
    </li>
  </ul>
</div>

