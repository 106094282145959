import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule} from '@angular/core';
import { AuthenticationInterceptorServiceService } from './core/security/authentication-interceptor-service.service';
import { HttpXsrfInterceptorServiceService } from './core/security/http-xsrf-interceptor-service.service';
import { CoreModule } from './core/core.module';
import { LayoutModule } from './layout/layout.module';
import { RoutesModule } from './routes/routes.module';
import { SharedModule } from './shared/shared.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { PublicLayoutModule } from './public-layout/public-layout.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FileUploadModule } from 'ng2-file-upload';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { NgSelectModule } from '@ng-select/ng-select';
import { CommonModule } from '@angular/common';
//import { CargasConflictosSapComponentComponent } from './cargas-conflictos-sap-component/cargas-conflictos-sap.component.ts';
import { BrowserModule } from '@angular/platform-browser';
import {DatePipe} from '@angular/common';


@NgModule({

  declarations: [AppComponent],
  imports: [
    BrowserAnimationsModule,
    CoreModule,
    LayoutModule,
    PublicLayoutModule,
    RoutesModule,
    SharedModule.forRoot(),
    FileUploadModule,
    NgbModule,
    NgSelectModule,
    FormsModule,
    CommonModule,
    ReactiveFormsModule,
    BrowserModule
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticationInterceptorServiceService,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpXsrfInterceptorServiceService,
      multi: true
    },
    DatePipe
  ],
  exports: [FormsModule],
  bootstrap: [AppComponent]
})
export class AppModule {}

//TODO: Refactorizar nombramientos componentes
