// This file can be replaced during build by using the `fileReplacements` array.
// `ng build ---prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

/**
 * Aqui se pueden definir variables que se cargan cuando se ejecuta la aplicacion en local o cuando se compila sin el comando buildpnd
 */

let xsrfToken = 'XSRF-TOKEN-DLLO';
let localBack = 'https://local.suranet.com:8080/adminbotsapi/api/';
let recaptchaSiteKey = '6LfYJa4UAAAAAHoD09qFZxycFj7eMJoLAF3GePK8';

if (location.href.includes('adminbots.dllosura')) {
  xsrfToken = 'XSRF-TOKEN-DLLO';
  localBack = 'https://adminbotsapi.dllosura.com/adminbotsapi/api/';
  recaptchaSiteKey = '6LfYJa4UAAAAAHoD09qFZxycFj7eMJoLAF3GePK8';
} else if (location.href.includes('adminbots.labsura')) {
  xsrfToken = 'XSRF-TOKEN-LAB';
  localBack = 'https://adminbotsapi.labsura.com/adminbotsapi/api/';
  recaptchaSiteKey = '6LfvJa4UAAAAAAp_gJ8bJWNhIxDfj4OtO_MIg5gG';
} else if (location.href.includes('adminbots.suramericana')) {
  xsrfToken = 'XSRF-TOKEN';
  localBack = 'https://adminbotsapi.suramericana.com.co/adminbotsapi/api/';

  recaptchaSiteKey = '6LcOJq4UAAAAADQeSEYIRuruFUtaixNXqmH0ZLv1';
}

export const environment = {
  /**
   * indica si el ambiente es productivo, cuando es true se habilita la configuracion de angular para aplicaciones en producción
   */
  production: true,
  xsrfToken,
  localBack,
  recaptchaSiteKey
};

/*
 * In development mode, to ignore zone related error stack frames such as
 * `zone.run`, `zoneDelegate.invokeTask` for easier debugging, you can
 * import the following file, but please comment it out in production mode
 * because it will have performance impact when throw error
 */
