<nav class="navbar navbar-light bg-sura">
  <div class="container">
    <a class="navbar-brand" href="adminbots/public/home">
      <img
        class="logo-header"
        src="assets/images/content/logo-sura.png"
        alt="Sura logo"/>
    </a>
  </div>
</nav>


