import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PublcSideBarComponent } from './publc-side-bar/publc-side-bar.component';
import { SharedModule } from '../shared/shared.module';
import { PublicLayoutComponent } from './public-layout.component';
import { PublicHeaderComponent } from './public-header/public-header.component';

@NgModule({
  imports: [SharedModule, CommonModule],
  declarations: [
    PublicLayoutComponent,
    PublicHeaderComponent,
    PublcSideBarComponent
  ],
  exports: [PublicLayoutComponent, PublicHeaderComponent, PublcSideBarComponent]
})
export class PublicLayoutModule {}
