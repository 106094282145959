import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-publc-side-bar',
  templateUrl: './publc-side-bar.component.html',
  styleUrls: ['./publc-side-bar.component.css']
})
export class PublcSideBarComponent implements OnInit {
  constructor() {}

  ngOnInit() {}
}
