import { Routes } from '@angular/router';

import { LayoutComponent } from '../layout/layout.component';
import { PublicLayoutComponent } from '../public-layout/public-layout.component';
// gen_imports

/**
 * objeto que almacena la ruta base sobre la cual se asigna el modulo manejador
 */
export const APP_ROUTES: Routes = [
  {
    path: 'adminbots',
    component: PublicLayoutComponent,
    children: [
      { path: '', redirectTo: 'public', pathMatch: 'full' },
      { path: 'public', redirectTo: 'public', pathMatch: 'full' },
      {
        path: 'public',
        loadChildren: () =>
          import('./adminbot-public/adminbot-public.module').then(
            (m) => m.PublicAdminbotModule
          )
      }
    ]
  },
  {
    path: 'adminbots',
    component: LayoutComponent,
    children: [
      { path: 'private', redirectTo: 'private', pathMatch: 'full' },
      {
        path: 'private',
        loadChildren: () =>
          import('./adminbot/adminbot.module').then((m) => m.AdminbotModule)
      },
      { path: 'admin', redirectTo: 'admin', pathMatch: 'full' },
      {
        path: 'admin',
        loadChildren: () =>
          import('./adminbot-admin/adminbot-admin.module').then(
            (m) => m.AdminClassAdminbotModule
          )
      }
    ]
  },
  { path: '**', redirectTo: 'adminbots' }
];
