import { MenuHeader } from '../sidebar/menu-header';
/**
 * Clase que contiene la información de seguridad de un usuario
 */
export interface IdentityUser {
  /** Nombre de usuario */
  usuarioRed: string;
  /** Nombre completo del usuario */
  nombreCompleto: string;
  /** Menu al que el usuario tiene acceso */
  menu: MenuHeader;
  /** Ultima vez que el usuario accedió al sistema */
  fechaUltimaConexion: string;
  /** Url para hacer logout completo (elimina el single-sign-on del usuario) */
  fullUrlLogout: string;
  /** Url para hacer logout parcial  */
  urlLogout: string;
  /**Identificacion usuario */
  dni: string;
}
