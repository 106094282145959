import { MenuItem } from './menu-item';

/**
 * Entidad que representa el encabezado del menú
 */
export interface MenuHeader {
  /**
   * Coleccion de items del menú
   */
  menuItems: Array<MenuItem>;

  /**
   * Nombre del menú
   */
  name: string;
}
