/**
 * Entidad, representa cada item del menú (la estructura esta basada en la de SEUS)
 */
export interface MenuItem {
  /**
   * Identificador del item
   */
  cdresource: number;

  /**
   * Path que se  define en el router
   */
  contextPath: string;

  /**
   * Nombre del item
   */
  name: string;

  /**
   * Icono asociado, este proyecto soporta iconos de font-awsome {@link https://fontawesome.com/} ejemplo: fa fa-user
   */
  image: string;

  /**
   * Indica el orden del item
   */
  order: number;

  /**
   * target
   */
  target: string;

  /**
   * windowOptions
   */
  windowOptions: string;

  /**
   * Items hijos
   */
  children: Array<MenuItem>;

  /**
   * Usada para saber si un item esta activo o no
   */
  active: boolean;
}
