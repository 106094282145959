import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import Swal from 'sweetalert2';
import { deleteAllCookies } from 'cookies-utils';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationInterceptorServiceService {
  xssrfToken: string = environment.xsrfToken;

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const headers = req.headers
      .set('Access-Control-Allow-Methods', 'GET,HEAD,OPTIONS,POST,PUT,DELETE')
      .set('X-APP-RELAYSTATE', location.href)
      .set('Access-Control-Allow-Origin', '*');

    const reqWithHeaders = req.clone({ headers, withCredentials: true });

    return next.handle(reqWithHeaders).pipe(
      tap(
        (event: HttpEvent<any>) => {},
        (error: any) => {
          if (error instanceof HttpErrorResponse) {
            switch (error.status) {
              case 200:
                window.location.href = `${error.url}`;
                break;
              case 401:
                document.body.innerHTML = error.error;
                document.forms[0].submit();
                break;
              case 403:
                Swal.fire(
                  'Error',
                  'No esta autorizado para ver esta página',
                  'error'
                );
                break;
              case 500:
                Swal.fire({
                  title: 'Error',
                  text: 'Al parecer hay un problema',
                  position: 'top',
                  icon: 'error',
                  timer: 5000
                }).then(() => {
                  //<<SOLUCION TEMPORAL>>
                  for (let i = 0; i < 700; i++) {
                    document.cookie = `XSRF-TOKEN${i}=borrar${i}; path=/;`;
                  }
                  deleteAllCookies();
                  /////////////------><------///
                  window.location.href = 'adminbots/public/home';
                });
                break;
              case 0:
                Swal.fire({
                  position: 'top-end',
                  icon: 'error',
                  title: 'Ha ocurrido un error',
                  text: 'Por favor, vuelve a intentar',
                  showConfirmButton: false,
                  timer: 4000
                }).then(() => {
                  //Borrado de cookies
                  //console.log("---->Todo: " + document.cookie)
                  //<<BORRADO DEL TOKEN>>
                  //console.log("---------------------> borrar: " + this.xssrfToken);
                  //document.cookie = "XSRF-TOKEN-DLLO=borrar; domain=.suranet.com; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT"
                  //document.cookie = this.xssrfToken + "=borrar; domain=.suranet.com; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT"

                  //<<BORRADO DEL APPTAG>>
                  //document.cookie = "appTagDllo=borrar; domain=.suranet.com; path=/;"
                  //document.cookie = "appTagDllo=borrar; domain=.suranet.com; path=/; expires=Thu, 01 Jan 1970 00:00:00 GMT"

                  //<<SOLUCION TEMPORAL>>
                  for (let i = 0; i < 700; i++) {
                    document.cookie = `XSRF-TOKEN${i}=borrar${i}; path=/;`;
                  }
                  deleteAllCookies();
                  /////////////------><------///
                  window.location.href = 'adminbots/public/home';
                });
            }
          }
        }
      )
    );
  }
}
