import { Component, Input } from '@angular/core';
import {
  state,
  style,
  trigger,
  transition,
  animate
} from '@angular/animations';
import { SidebarService, MenuItem } from 'src/app/core/sidebar';

/**
 * Componente de presentación encargado de rederizar submenus
 */
@Component({
  selector: 'app-submenu',
  templateUrl: './submenu.component.html',
  styleUrls: ['./submenu.component.scss'],
  animations: [
    trigger('anim', [
      state(
        'false',
        style({
          height: '0',
          padding: '0'
        })
      ),
      state(
        'true',
        style({
          height: '*'
        })
      ),
      transition('false => true', animate('250ms ease-in')),
      transition('true => false', animate('250ms ease-out'))
    ])
  ]
})
export class SubmenuComponent {
  /**
   * items que deben ser renderizados
   */
  /**
   * items que deben ser renderizados
   */

  @Input()
  itemsMenu: Array<any> = [];
  /**
   * indica si se debe mostrar el submenu, esto ejecuta la animación
   */
  /**
   * indica si se debe mostrar el submenu, esto ejecuta la animación
   */
  @Input()
  visible = false;
  /**
   * path del menu padre, este se usa para completar el enrutamiento
   */
  @Input() basePath = '';

  /**
   * valor para aplicar un padding izquierdo a un subitem, valores validos:
   * 10: aplica padding-left 10
   * 20: aplica padding-left 20
   * 30: aplica padding-left 30
   * 40: aplica padding-left 40
   * 50: aplica padding-left 50
   */
  @Input() paddingLeft = 10;

  /**
   * Constructor
   *
   * @param sidebarService servicio del sidebar
   */
  constructor(private sidebarService: SidebarService) {}

  /**
   * Cambia el estado activo/inactivo del menuitem pasado como parametro
   *
   * @param menu objeto menuitem seleccionado
   */
  toggleSubmenu(menu: MenuItem): void {
    // desactivamos todos los menus
    const isActive = menu.active;
    this.sidebarService.inactivateAllItems(this.itemsMenu);

    menu.active = !isActive;
  }
}
